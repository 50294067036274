<template>
    <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  created: function () {
    if (this.active_contract) {
      // this.$router.push({ name: 'Cards', params: { contract: this.active_contract } })
    }
  },
  computed: {
    ...mapGetters([
      'active_contract'
    ])
  },
  watch: {
    active_contract: function () {
      this.$router.push({ name: 'Dashboard', params: { contract: this.active_contract } })
    }
  }
}
</script>