<template>
<div class="workspace " v-if="loading">
    <div class="loader">Loading...</div>
</div>
  <div class="workspace" v-else>
      <div class="s s100">
          <div class="box">
                <h1 v-if="Object.keys(alertData).length > 0 && Object.keys(deployData).length > 0">{{chartTitle}}</h1>
                <DeploymentTimeline v-if="Object.keys(alertData).length > 0 && Object.keys(deployData).length > 0" id="deployTimes" :cssClasses="'chart'" :chartdata="chartdata" />
                <h1 v-else>{{chartTitle}}</h1>
          </div>
          <div class="desc">Visual of production deployments over the last 10 days and number of resulting alerts and issues received.</div>
      </div>
      <div class="s s100">
        <div class="s s50">
            <div class="box">
                  <h1 v-if="chartData.length > 0">Deployment Frequency</h1>
                  <DeploymentFrequency v-if="chartData.length > 0" :data='chartData' :cssClasses="'chart'" />
                  <h1 v-else>{{chartTitle}}</h1>
            </div>
            <div class="desc">Number of deployments according to interval since last deployment, in addition to number of resulting alerts and issues according to frequency of deployment.</div>
        </div>
        <div class="s s50">
            <div class="box">
                <h1 v-if="chartData.length > 0">Dev to Production Average Timeframe</h1>
                <DeploymentAverageTimeframe v-if="chartData.length > 0" :data='chartData' :cssClasses="'chart'" />
                <h1 v-else>{{chartTitle}}</h1>
            </div>
            <div class="desc">Average interval from deploying to dev to deploying to production along with suggested target.</div>
        </div>
      </div>
  </div>
</template>

<script>
import DeploymentTimeline from '../components/Dashboard/DeploymentTimeline'
import DeploymentFrequency from '../components/Dashboard/DeploymentFrequency'
import DeploymentAverageTimeframe from '../components/Dashboard/DeploymentAverageTimeframe'

import { mapGetters } from "vuex";
import moment from 'moment'

export default {
  name: 'Dashboard',
  data () {
    return {
        chartData: {},
        chartOptions: {},
        // week: moment().format('WW'),
        // month: moment().format('MM'),
        // year: moment().year(),
        // period: this.settings.period || 'week',
        added: [],
        removed: [],
        loading: true,
        alertData: {},
        deployData:{},
        chartTitle: 'Deployment Timeline'
    }
  },
  props: [
    'settings'
  ],
  components: {
    DeploymentTimeline,
    DeploymentFrequency,
    DeploymentAverageTimeframe,
  },
    computed: {
    ...mapGetters([
        'id_token',
        'active_contract',
        'time'
        ]),
      chartdata: function() {
        return {
          datasets: [
            this.alertData.datasets[0],
            this.deployData.datasets[0]
          ]
        }
      }
    },
  methods: {
      getMetrics: function () {
        var vm = this
        this.withAuth(function () {
            var headers = {}
            headers['Authorization'] = 'Bearer ' + vm.id_token
            vm.$http.get(process.env.VUE_APP_GATEWAY_ENDPOINT + '/opsgenie/alerts/summary/' + vm.active_contract + '/' + vm.time.period + '/' + `${vm.time.year}-${vm.time.week}`, { headers: headers })
            .then(response => {
              console.log('alerts data', response)
              if (response.data.incidents.length > 0) {
                vm.alertData = vm.setAlertData(response.data.incidents)
                vm.chartTitle = 'Deployment Timeline: ' + vm.time.year + '-' + vm.time.week
              } else {
                vm.chartTitle = `No data to display for - ISO ${vm.time.period}:${vm.time.week} / Year:${vm.time.year}`
              }

              setTimeout(() => {
                vm.loading = false
              }, 2000)
            })
            .catch(function(error) {
              vm.chartTitle = `No data to display for - ISO ${vm.time.period}:${vm.time.week} / Year:${vm.time.year}`
              // vm.flashMessage.error({
              //   title: error,
              //   message: 'Failed to retrieve Opsgenie alert data for ' + vm.active_contract
              // })
              vm.loading = false
              vm.alertData = {}
            })

            vm.$http.get(process.env.VUE_APP_GATEWAY_ENDPOINT + '/cloudformation/history/' + vm.active_contract + '/' + vm.time.period + '/' + `${vm.time.year}-${vm.time.week}`, { headers: headers })
            .then(response => {
                console.log('deploy data', response)
                // vm.chartData = vm.setDataForTimeline(vm.sortDeploymentData(response.data.diffs))
                if (response.data.diffs.length > 0) {
                  vm.deployData = vm.setDeployData(response.data.diffs)
                  vm.chartTitle = 'Deployment Timeline: ' + vm.time.year + '-' + vm.time.week
                } else {
                  vm.chartTitle = `No data to display for - ISO ${vm.time.period}:${vm.time.week} / Year:${vm.time.year}`
                }
                // vm.date = vm.metrics[vm.metrics.length - 1].deploy.date
                // vm.lastUpdate = vm.date
                // vm.graphProperties = {name: 'Deployment', lastUpdate: vm.lastUpdate, date: vm.date, period: vm.period}
              setTimeout(() => {
                vm.loading = false
              }, 2000)
            })
            .catch(function (error) {
              vm.chartTitle = `No data to display for - ISO ${vm.time.period}:${vm.time.week} / Year:${vm.time.year}`
              // vm.flashMessage.error({
              //   title: error,
              //   message: 'Failed to retrieve cloudformation data for ' + vm.active_contract
              // })
              vm.loading = false
              vm.deployData = {}
            })
        })
    },
    setDeployData: function(deployment) {
      let chartdata =  
        {
        datasets:[
          {
            label: 'Deployments',
            data: [],
            pointBackgroundColor: 'rgb(255,255,255)',
            pointBorderColor: 'rgb(33,52,56)',
            hoverBackgroundColor: 'rgb(255,255,255)',
            hoverBorderColor: 'rgb(33,52,56)',
            radius: 8,
            hoverRadius: 8,
            borderWidth: 4,
            hoverBorderWidth: 4,
            fill: false,
            showLine: false,
            spanGaps: false,
          }
        ]
        }

      deployment.forEach((element, index) => {
        chartdata.datasets[0].data.push(
          {
            x: moment.unix(element.deploy.date).format('YYYY-MM-DD hh:mm:ss'), //2020-10-27 09:30:20
            y: Object.keys(element.added).length + Object.keys(element.removed).length,
            label: element.project,
            // dataIndex: index
          }
        )
      })
      
      console.log('deploy datasets',chartdata)
      return chartdata;
    },
    setAlertData: function(incidents) {
      //create chartdata here for opsgenie report
      let chartdata = 
      {
        datasets: [
          {
            label: 'Number of Issues',
            backgroundColor: 'rgba(171, 119, 197, 1)',
            borderWidth: 0,
            pointBackgroundColor: 'rgba(255,255,255,0)',
            pointBorderColor: 'rgba(255,255,255,0)',
            data: [

            ]
          }
        ]
      }

      // let datasets = []
      incidents.forEach(element => {
        chartdata.datasets[0].data.push(
          {
            x: moment.unix(element.opened),
            y: element.alerts.length
          },
          {
            x: moment.unix(element.closed),
            y: element.alerts.length
          }
        )
      });
      return chartdata;
    },
    sortDeploymentData (data) {
        let sortedDeployment = data.sort(function (a, b) {
        let dateA = moment.unix(a.deploy.date)
        let dateB = moment.unix(b.deploy.date)

        if (dateA.diff(dateB) > 0) return 1
        if (dateA.diff(dateB) < 0) return -1

        return 0
      })
      let range = sortedDeployment.length - 12
      let sortedData = sortedDeployment.slice(range, sortedDeployment.length)
      return sortedData
    },
    setDataForTimeline (data) {
      return data.map((element, index) => {
        let totalAdd = 0 // eslint-disable-line
        let totalRemove = 0 // eslint-disable-line
        let totalDeployed = 0 // eslint-disable-line
        const RANGE = 11

        for (const key in element.added) {
          totalAdd += element.added[key]
          totalDeployed += element.added[key]
        }

        this.added.push(totalAdd)

        for (const key in element.removed) {
          totalRemove += element.removed[key]
          totalDeployed += element.removed[key]
        }

        this.removed.push(totalRemove)

        element.count = totalDeployed
        element.label = this.week - RANGE + index
        element.total_added = totalAdd
        element.total_removed = totalRemove
        return element
      })
    }
  },
  mounted: function() {
    var vm = this
    this.$store.watch(
      function(state, store) {
        return store.time
      },
      function() {
        vm.loading = true
        vm.getMetrics()
      },
      {
        deep:true
      }
    )
    this.getMetrics()
  },
  watch: {
      active_contract: function () {
        this.loading = true
        this.getMetrics()
      },
      settings: function(changes) {
        this.loading = true
        this.period = changes.period
        this.getMetrics()
      }
  }
}
</script>

<style scoped src="../assets/stylesheets/Dashboard.css"></style>
<style scoped>
.loader,
.loader:before,
.loader:after {
  background: #03befc;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #03befc;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
</style>