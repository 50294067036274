<template>
<div>
  <h1>Analytics</h1>
  <span><h1>Period: {{settings.period}}</h1><h1>Contract: {{settings.contract}}</h1></span>
</div>
</template>

<script>
export default {
  name: 'Analytics',
  props: [
    'settings'
  ]
}
</script>