import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'

import FlashMessage from '@smartweb/vue-flash-message';
import Moment from 'vue-moment'

Vue.config.productionTip = false
Vue.prototype.$http = axios

Vue.use(FlashMessage, {strategy: 'multiple'})

Vue.mixin({
  methods: {
    withAuth: function (callback) {
      var now = Math.floor(new Date().getTime() / 1000) // current time in seconds
      var exp = store.getters.idTokenData.exp // expiry time of current token in seconds
      var min = 60 // minimum time before expiry in seconds
      console.log('Token valid for another ' + Math.ceil((exp - min - now) / 60) + ' minutes')
      if (exp - min - now < 0) {
        console.log('Token needs to be refreshed')
        this.refreshAuthTokens(callback)
      } else {
        console.log('Token does not need to be refreshed')
        callback()
      }
    },
    refreshAuthTokens: function (callback) {
      var headers = {}
      headers['Content-Type'] = 'application/x-www-form-urlencoded'
      this.$http.post(
        process.env.VUE_APP_AUTH_ENDPOINT + '/oauth2/token',
        'grant_type=refresh_token&client_id=' + process.env.VUE_APP_COGNITO_APP_CLIENT_ID +
        '&refresh_token=' + store.state.auth.refresh_token,
        { headers: headers })
        .then(response => {
          console.log('refresh token',response)
          if (response.data) {
            store.dispatch('SET_TOKEN', response.data)
            console.log('Token refreshed')
            callback()
          }
        })
    }
  }
})

new Vue({
  router,
  store,
  FlashMessage,
  Moment,
  axios,
  render: h => h(App)
}).$mount('#app')
