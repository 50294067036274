<script>
import { Line } from 'vue-chartjs'
import moment from 'moment'

export default {
    extends: Line,
    data () {
      return {
      chartdata: {
        datasets: []
      },
      options:  {
            responsive: true, 
            maintainAspectRatio: false
        },
        labels: [],
        added: [],
        removed: [],
        count: [],
        countDifference: []
      }
    },
    props: {
      data: {
        type: Array,
        default: null
      }
    },
    watch: {
    chartdata: function() {
      this.renderChart(this.chartdata, this.options)
    },
    data: function() {
        this.data.forEach((element, index) => {
            this.labels.push(`${moment.unix(element.deploy.date).format("DD/MM/YYYY")}`)
            if (index !== 0) {
              this.countDifference.push(element.count - this.data[index - 1].count)
            } else {
              this.countDifference.push(element.count)
            }
        });
        this.chartdata.datasets = [
            {
              label: 'Total Deployment',
              backgroundColor: '#A9D2D5',
              data: this.countDifference
            }
        ]
    },
    labels: function(labels) {
        this.chartdata.labels = labels
        this.renderChart(this.chartdata, this.options)
    }
  }
}
</script>