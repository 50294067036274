import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/auth'
import accounts from '@/store/accounts'
import contracts from '@/store/contracts'
import settings from '@/store/settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    accounts,
    contracts,
    settings
  }
})
