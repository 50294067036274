import deepmerge from 'deepmerge'
import moment from 'moment'

export default {
  state: {
    defaults: {
      global: {
        dots: true,
        weekNumbers: true
      },
      work: {
        pin: null,
        queue: false,
        zendesk: false,
        trello: true,
        jira: true,
        icons: true
      }
    },
    settings: {},
    period: 'week',
    week: moment().format('WW'),
    month: moment().format('MM'),
    year: moment().format('YYYY'),
},
  getters: {
    settings: state => {
      return state.settings
    },
    time: state => {
      return {
        period: state.period,
        week: state.week,
        month: state.month,
        year: state.year
      }
    }
  },
  mutations: {
    SET_SETTINGS (state, settings) {
      state.settings = settings
      localStorage.setItem('settings', JSON.stringify(settings))
    },
    SET_PERIOD (state, period) {
      state.period = period
    },
    SET_WEEK (state, week) {
      state.week = moment().isoWeek(week).format('WW')
    },
    SET_MONTH (state, month) {
      state.month = moment().month(month).format('MM')
    },
    SET_YEAR (state, year) {
      state.year = year
    }
  },
  actions: {
    LOAD_SETTINGS (context, settings) {
      context.commit('SET_SETTINGS', deepmerge(context.state.defaults, settings))
    },
    UPDATE_SETTINGS (context, { namespace, key, value }) {
      let setting = {
        [namespace]: {
          [key]: value
        }
      }
      context.commit('SET_SETTINGS', deepmerge(context.state.settings, setting))
    },
    RESET_SETTINGS (context, { namespace, key }) {
      let setting = {
        [namespace]: {
          [key]: context.state.defaults[namespace][key]
        }
      }
      context.commit('SET_SETTINGS', deepmerge(context.state.settings, setting))
    }
  }
}
