<template>
<div class="top">
  <div class="left">
      <div class="main_title">{{getCurrentTitle}}</div>
      <div class="toggle_interval">
        <select name="toggle_int" id="toggle-int" v-model="period">
            <option value="week">Week</option>
            <option value="month">Month</option>
        </select>
      </div>
      <div class="toggle_interval">
        <select name="toggle_int" id="toggle-int" v-model="week">
            <option value="">Select Week</option>
            <option v-for="(week, index) in 52" :key="index" v-bind:value="(week).toLocaleString('en-US',{minimumIntegerDigits: 2})">{{(week).toLocaleString('en-US',{minimumIntegerDigits: 2})}}</option>
        </select>
      </div>
      <div class="toggle_interval">
        <select name="toggle_int" id="toggle-int" v-model="year">
            <option value="">Select Year</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
        </select>
      </div>
  </div>
  <div class="right">
      <div class="toggle_large">
        <select name="toggle_comp" id="toggle-comp" v-model="active_contract">
            <option v-for="contract in contracts" 
            :key="contract" 
            v-bind:value="contract" 
            :selected="active_contract === contract ? true : false"
            >{{contract}}
            </option>
        </select>
      </div>
      <div class="options">
          <a href="#" class="settings"></a>
          <a class="profile"></a>
      </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "Settings",
  data () {
      return {
          period: '',
          week: '',
          year: ''
      }
  },
  computed: {
      getCurrentTitle () {
        return this.$route.name
      },
    ...mapGetters([
      // 'active_contract',
      'contracts',
      'time'
    ]),
    active_contract: {
      get: function () {
        return this.$store.getters.active_contract
      },
      set: function (contract) {
        this.$store.dispatch('SET_ACTIVE_CONTRACT', contract)
      }
    }
  },
  mounted: function() {
    this.period = this.time.period
    this.week = this.time.week
    this.year = this.time.year

    this.emitSettings()
  },
  methods: {
    emitSettings: function () {
      this.$emit('returnSettings', {period: this.period, contract: this.active_contract})
    },
    updateContract: function (contract) {
      console.log('update contract', contract)
      this.$store.dispatch('SET_ACTIVE_CONTRACT', contract)
    },
  },
  watch: {
    period: function (evt) {
      console.log('period change', evt)
      this.$store.commit('SET_PERIOD', evt)
    },
    week: function (evt) {
      console.log('week change', evt)
      this.$store.commit('SET_WEEK', evt)
    },
    year: function (evt) {
      console.log('year change', evt)
      this.$store.commit('SET_YEAR', evt)
    },
    contracts: function () {
      this.emitSettings()
    }
  }
}
</script>

<style scoped src="../assets/stylesheets/Settings.css"></style>