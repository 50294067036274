
import axios from 'axios'

export default {
  state: {
    contracts: [],
    active_contract: null
  },
  getters: {
    contracts: state => {
      return state.contracts
    },
    active_contract: state => {
      return state.active_contract
    }
  },
  mutations: {
    SET_CONTRACTS (state, contracts) {
      state.contracts = contracts
      if (!state.active_contract) {
        state.active_contract = state.contracts[0]
      }
    },
    SET_ACTIVE_CONTRACT (state, contract) {
      state.active_contract = contract
    }
  },
  actions: {
    GET_CONTRACTS (context, vm) {
      vm.withAuth(function () {
        var headers = {}
        headers['Authorization'] = 'Bearer ' + vm.$store.getters.id_token
        axios.get(process.env.VUE_APP_GATEWAY_ENDPOINT + '/contracts', { headers: headers })
          .then(response => {
            console.log(response)
            if (response.data) {
              let contracts = response.data.contracts.map(contract => contract.id)
              contracts.sort(function (a, b) {
                if (a === 'base2') { return -1 }
                if (b === 'base2') { return 1 }
                if (a < b) { return -1 }
                if (b < a) { return 1 }
              })
              context.commit('SET_CONTRACTS', contracts)
            }
          })
      })
    },
    SET_ACTIVE_CONTRACT (context, contract) {
      context.commit('SET_ACTIVE_CONTRACT', contract)
    }
  }
}
