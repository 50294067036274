
import axios from 'axios'

export default {
  state: {
    accounts: []
  },
  getters: {
    accounts: state => {
      return state.accounts
    }
  },
  mutations: {
    SET_ACCOUNTS (state, accounts) {
      state.accounts = accounts
    }
  },
  actions: {
    GET_ACCOUNTS (context, vm) {
      vm.withAuth(function () {
        var headers = {}
        headers['Authorization'] = 'Bearer ' + vm.$store.getters.id_token
        axios.get(process.env.VUE_APP_GATEWAY_ENDPOINT + '/accounts/' + context.getters.active_contract, { headers: headers })
          .then(response => {
            console.log(response)
            if (response.data) {
              context.commit('SET_ACCOUNTS', response.data.accounts)
            }
          })
      })
    }
  }
}
