import axios from 'axios'

export default {
  state: {
    authenticated: false,
    code: null,
    token: null,
    refresh_token: null
  },
  getters: {
    id_token: state => {
      return state.token.id_token
    },
    idTokenData: state => {
      if (state.token.id_token) {
        return JSON.parse(atob(state.token.id_token.split('.')[1]))
      }
    },
    authenticated: state => {
      return state.authenticated
    }
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
      state.authenticated = true
      localStorage.setItem('token', JSON.stringify(token))
    },
    SET_REFRESH_TOKEN (state, refreshToken) {
      state.refresh_token = refreshToken
      localStorage.setItem('refresh_token', JSON.stringify(refreshToken))
    }
  },
  actions: {
    SET_TOKEN ({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    SET_REFRESH_TOKEN ({ commit }, refreshToken) {
      commit('SET_REFRESH_TOKEN', refreshToken)
    },
    GET_TOKENS ({ commit }, code) {
      console.log('GET_TOKENS action')
      console.log(code)
      var headers = {}
      headers['Content-Type'] = 'application/x-www-form-urlencoded'
      if (typeof code !== 'undefined') {
        console.log(this)
        axios.post(
          process.env.VUE_APP_AUTH_ENDPOINT + '/oauth2/token',
          'grant_type=authorization_code&client_id=' + process.env.VUE_APP_COGNITO_APP_CLIENT_ID +
          '&redirect_uri=' + process.env.VUE_APP_PORTAL_ENDPOINT +
          '&code=' + code,
          { headers: headers })
          .then(response => {
            console.log(response)
            if (response.data) {
              commit('SET_TOKEN', response.data)
              commit('SET_REFRESH_TOKEN', response.data.refresh_token)
            }
          })
      }
    }
  }
}
