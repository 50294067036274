<template>
<div>
  <input type="checkbox" id="menu-toggle" class="menu-toggle">
  <label for="menu-toggle"></label>
  <div class="menu">
      <a href="https://www.base2services.com/" target="_blank" class="logo"></a>
      <span>DevOps Portal</span>
      <ul class="menu_items">
          <li class="home"><router-link :to="{ name: 'Dashboard', params: { contract: this.active_contract }}">Dashboard</router-link></li>
          <li class="analytics"><router-link :to="{name: 'Analytics', params: { contract: this.active_contract }}">Analytics</router-link></li>
      </ul>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavBar',
  computed: {
    ...mapGetters([
      'active_contract',
      'contracts'
    ])
  },
  watch: {
    active_contract: function () {
      if (this.active_contract) {
        this.$router.push({ name: this.$router.$route, params: { contract: this.active_contract} }).catch(() => {})
      }
    }
  }
}
</script>

<style scoped src='../assets/stylesheets/NavBar.css'></style>