import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Analytics from '../views/Analytics.vue'
import Home from '../views/Home'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      props: true,
      meta: {
        requiresAuth: false,
        admin: false
      }
    },
    {
      path: '/:contract/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      props: true,
      meta: {
        requiresAuth: true,
        admin: true
      }
    },
    {
      path: '/:contract/analytics',
      name: 'Analytics',
      component: Analytics,
      meta: {
        requiresAuth: true,
        admin: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.authenticated) {
      next()
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
