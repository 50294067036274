<script>
import { Line } from 'vue-chartjs'
import moment from 'moment'
import { mapGetters } from "vuex";

export default {
  extends: Line,
  data () {
    return {
      // options: {
      //   legend: {
      //     display: false
      //   },
      //   hover: {
      //     mode: 'index',
      //     intersect: false
      //   },
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   scales: {
      //       xAxes: [
      //         {
      //           type: 'time',
      //           position: 'bottom',
      //           display: true,
      //           time: {
      //             unit: 'day'
      //           },
      //           ticks : {
      //             beginAtzero :true,
      //             min: moment().isoWeek(39).startOf('isoWeek').year(2020),//'2020-09-01T00:00:00+10:00', // the beginning week or month
      //             max: moment().isoWeek(39).endOf('isoWeek').year(2020)//'2020-10-01T23:59:00+10:00' // the ending week or month based on period
      //           }
      //       }],
      //       yAxes : [{
      //           scaleLabel : {
      //               display : false
      //           },
      //           ticks : {
      //               beginAtZero :true
      //           }
      //       }]
      //   }
      // },
      options: {
        animation: {
            duration: 0
        },
        hover: {
            animationDuration: 0
        },
        responsiveAnimationDuration: 0,
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            position: 'bottom'
        },
        tooltips: {
            enabled: true,
            intersect: true,
            callbacks: {
              label: function(tooltipItem, data) {
                // console.log('label tooltip cb - tooltip item', tooltipItem)
                // console.log('label tooltop cb - data', data)
                return data.datasets[1].data[tooltipItem.index].label || '';
              }
            }
        },
        scales: {
            xAxes: [
            {
                type: 'time',
                ticks: {
                  padding: 20,
                  // beginAtzero :true,
                  min: moment().week(this.$store.getters.time.week).year(this.$store.getters.time.year).startOf('isoWeek'),//'2020-09-01T00:00:00+10:00', // the beginning week or month
                  max: moment().week(this.$store.getters.time.week ).year(this.$store.getters.time.year).endOf('isoWeek')//'2020-10-01T23:59:00+10:00' // the ending week or month based on period
                },
                time: {
                    unit: 'day',
                }
            }],
            yAxes: [{
                ticks: {
                    display: true,
                    beginAtzero :true,
                    min: 0
                }
            }]
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'time'
    ])
  },
  props: {
    period: {
      type: String,
      default: 'week'
    },
    chartdata: {
      type: Object,
      default: function () {
        return {
          // datasets: [
          //   {
          //       label: 'Scatter Dataset',
          //       backgroundColor: "rgba(246,156,85,1)",
          //       borderColor: "rgba(246,156,85,1)",
          //       fill: false,
          //       borderWidth : 15,
          //       pointRadius : 0,
          //       data: [
          //           {
          //               x: moment.unix(1601324164).format(), //YYYY-MM-DDT HH:MM:SS
          //               y: 9
          //           }, {
          //               x: moment.unix(1601714513).format(),
          //               y: 9
          //           }
          //       ]
          //   },
          // ]
          display: false,
          datasets: [{
            label: 'Deployments',
            data: [
                {
                    x: '2020-10-27 09:30:20',
                    y: 1
                }, {
                    x: '2020-10-30 11:29:30',
                    y: 10
                }, {
                    x: '2020-10-30 13:40:30',
                    y: 10
                }, {
                    x: '2020-10-31 09:29:30',
                    y: 10
                }, {
                    x: '2020-11-01 14:30:20',
                    y: 10
                }, {
                    x: '2020-11-03 12:30:20',
                    y: 10
                }
            ],
            pointBackgroundColor: 'rgb(255,255,255)',
            pointBorderColor: 'rgb(33,52,56)',
            hoverBackgroundColor: 'rgb(255,255,255)',
            hoverBorderColor: 'rgb(33,52,56)',
            radius: 8,
            hoverRadius: 8,
            borderWidth: 4,
            hoverBorderWidth: 4,
            fill: false,
            showLine: false,
            spanGaps: false
        }]
        }
      }
    }
  },
  mounted() {
    var ctx = document.getElementById('line-chart').getContext('2d');
    var fillPattern2 = ctx.createLinearGradient(0, 0, 0, 250);
    fillPattern2.addColorStop(1, "rgba(78,87,170,0.75)");
    fillPattern2.addColorStop(0, "rgba(140,26,98,0.75)");

    this.chartdata.datasets[0].backgroundColor = fillPattern2
    this.renderChart(this.chartdata, this.options)
  }
}

</script>

<style scoped src="../../assets/stylesheets/Dashboard.css"></style>