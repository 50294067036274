<template>
  <div id="app" class="app_wrapper">
    <div v-if="authenticated">
          <NavBar />
    </div>
    <div class="main">
      <Settings v-on:returnSettings='getSettings' />
      <router-view :settings="settings" />
      <FlashMessage :position="'left bottom'"></FlashMessage>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import Settings from '@/components/Settings'

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data () {
    return {
      settings: {},
      code: this.$route.query.code
    }
  },
  components: {
    NavBar,
    Settings
  },
  computed: {
    ...mapGetters([
      'authenticated'
    ])
  },
  methods: {
    getSettings: function(params) {
      this.settings = params
    }
  },
  created: function () {
    if (localStorage.getItem('token') && localStorage.getItem('refresh_token')) {
      this.$store.dispatch('SET_TOKEN', JSON.parse(localStorage.getItem('token')))
      this.$store.dispatch('SET_REFRESH_TOKEN', JSON.parse(localStorage.getItem('refresh_token')))
    } else {
      if (this.code) {
        this.$store.dispatch('GET_TOKENS', this.code)
        this.$router.replace('/')
      } else {
        window.location = process.env.VUE_APP_AUTH_ENDPOINT + '/login?' +
        'response_type=code&client_id=' + process.env.VUE_APP_COGNITO_APP_CLIENT_ID +
        '&redirect_uri=' + process.env.VUE_APP_PORTAL_ENDPOINT
      }
    }
    if (localStorage.getItem('settings')) {
      this.$store.dispatch('LOAD_SETTINGS', JSON.parse(localStorage.getItem('settings')))
    } else {
      this.$store.dispatch('LOAD_SETTINGS', {})
    }
  },
  watch: {
    authenticated: function () {
      if (this.authenticated) {
        this.$store.dispatch('GET_CONTRACTS', this)
      }
    },
    '$route.params.contract': {
      handler: function () {
        this.$store.dispatch('SET_ACTIVE_CONTRACT', this.$route.params.contract)
      },
      immediate: true
    }
  }
}
</script>

<style src='./assets/stylesheets/App.css'></style>
